// @mui
import { Box } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTheme } from "@mui/material/styles";

import { useSliderSettings } from "./useSliderSettings";

import { useAttributeMapping } from "../../../hooks/useAttributeMapping";
import { useGetAllUnitAttributes } from "../../../api/hooks";

import { SamplePrevArrow } from "./compounds/SamplePrevArrow";
import { SampleNextArrow } from "./compounds/SampleNextArrow";
import { AttributeSliderItem } from "./compounds/AttributeSliderItem/AttributeSliderItem";

type Props = {
  dataTestId: string;
};

const AttributeSlider = (props: Props) => {
  const theme = useTheme();
  const settings = useSliderSettings();
  const { data: allUnitGroupAttributes = [] } = useGetAllUnitAttributes();

  const allUnitGroupAttributesFlatten = allUnitGroupAttributes.map((g) => g.attributes).flat();

  const { unitAttributes: allAttributes } = useAttributeMapping({
    unitAttributes: allUnitGroupAttributesFlatten,
  });

  return (
    <Box mx="auto" px={4} position={"relative"} data-testid={props.dataTestId}>
      <Swiper {...settings}>
        {allAttributes
          .map((g) => g.attributes)
          .flat()
          .filter((attr) => !!attr.icon)
          .filter((attr) => attr.show_in_main_slider)
          .map((attr) => (
            <SwiperSlide key={attr.code}>
              <AttributeSliderItem attribute={attr} />
            </SwiperSlide>
          ))}
      </Swiper>
      <SamplePrevArrow className="arrow-left" bgColor={theme.palette.background.default} />
      <SampleNextArrow className="arrow-right" bgColor={theme.palette.background.default} />
    </Box>
  );
};

export { AttributeSlider };
