// @mui
import { Card, CardContent, Typography, useMediaQuery, Box } from "@mui/material";

// utils

// components

import { PropertyPromotionLabel } from "../../property/PropertyPromotionLabel";

import { HotelDto } from "../../../../api/api-client/service";
import { useTheme } from "@mui/system";
import { HotelCardSkeleton } from "./HotelCardSkeleton";

import { Link } from "../../../../fe-ui/atoms/Link/Link";
import { S3ImageLight } from "../../../../fe-ui/atoms/S3Image/S3ImageLight";
import { truncate } from "../../../../fe-ui/utils/truncate";
import { hotelIdToLogoMap, hotelLogoSizeMap } from "./hotelIdToLogoMap";
import { useGetAllHotelsPromotionLabels } from "../../../../api/hooks";
import { fixAssetPath } from "../../../../fe-ui/utils/fixAssetPath";
import { TextMaxLine } from "../../../../fe-ui/atoms/TextMaxLine/TextMaxLine";

// ----------------------------------------------------------------------

// TODO: Fix types
type Props = HotelDto & {
  subtitle?: string;
  link?: string;
  image?: string;
  tags?: string;
};

const IMAGE_HEIGHT_MOBILE = 300;
const IMAGE_HEIGHT_DEFAULT = 240;
const CARD_MAXWIDTH_MOBILE = 600;
const CARD_MAXWIDTH_DEFAULT = 420;

function HotelCardComponent(props: Props) {
  const { id, title, description, shortDescription, image, link, subtitle } = props;

  const { data: allHotelsPromotionLabels = [] } = useGetAllHotelsPromotionLabels();
  const promotionLabels = props.promotionLabels?.map((lbl) => allHotelsPromotionLabels.find((h) => h.id === lbl.id));

  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const imageHeight = isXS ? IMAGE_HEIGHT_MOBILE : IMAGE_HEIGHT_DEFAULT;
  const cardMaxWidth = isXS ? CARD_MAXWIDTH_MOBILE : CARD_MAXWIDTH_DEFAULT;
  const logoSize = `${hotelLogoSizeMap[id] || 80}%`;
  const truncatedDescription = truncate(shortDescription || description || "", 85);

  return (
    <Link
      data-ym={"HOTEL_CARD"}
      key={id}
      data-testid={`hotel-card-${id}`}
      sx={{ position: "relative" }}
      href={link}
      underline={"none"}
    >
      <Card
        sx={{
          maxWidth: cardMaxWidth,
          marginBottom: "20px",
        }}
      >
        <Box
          sx={
            hotelIdToLogoMap[id as string] || hotelIdToLogoMap[id]
              ? {
                  "&::after": {
                    content: "''",
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: "rgba(0,0,0,.35)",
                    backgroundImage: `url(${fixAssetPath(hotelIdToLogoMap[id as string] || hotelIdToLogoMap[id])})`,
                    backgroundSize: logoSize,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                  },
                }
              : undefined
          }
          style={{ position: "relative", overflow: "hidden", height: imageHeight, borderRadius: 16 }}
        >
          <S3ImageLight src={image || ""} alt={"Picture of the author"} size="small" />
        </Box>
        <CardContent sx={{ padding: 1 }}>
          {subtitle && (
            <Typography
              gutterBottom
              variant={"caption"}
              color={"inherit"}
              component={id ? Link : "span"}
              href={id ? `/hotel/${id}` : undefined}
              sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {subtitle || " "}
            </Typography>
          )}
          <Typography
            gutterBottom
            variant={"subtitle1"}
            component={"div"}
            sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {title}
          </Typography>
          <TextMaxLine minHeight={"3rem"} variant="body2" line={2}>
            {typeof description === "string" ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: truncatedDescription,
                }}
              />
            ) : (
              truncatedDescription
            )}
          </TextMaxLine>
        </CardContent>
        <PropertyPromotionLabel items={promotionLabels} />
      </Card>
    </Link>
  );
}

export function HotelCard(
  p: Props & {
    isLoading?: boolean;
  }
) {
  // TODO: Скелетоны в отдельные компоненты везде
  if (p.isLoading) {
    return <HotelCardSkeleton />;
  }
  return <HotelCardComponent {...p} />;
}
