import { useRouter } from "next/router";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { dateRangeAtom, filterSelectedAttributeIdsAtom, selectedHotelAtom } from "../state/search-state";
import { adultsCounterAtom, bookingReferralState, childrenCounterAtom } from "../state/booking-state";

export const useInitialStateFromQuery = () => {
  const router = useRouter();
  const setHotelId = useSetRecoilState(selectedHotelAtom);
  const setDateRange = useSetRecoilState(dateRangeAtom);
  const setSelectedAttrs = useSetRecoilState(filterSelectedAttributeIdsAtom);
  const setAdults = useSetRecoilState(adultsCounterAtom);
  const setChildren = useSetRecoilState(childrenCounterAtom);
  const setReferral = useSetRecoilState(bookingReferralState);

  useEffect(() => {
    if (router?.query) {
      const { query } = router;

      if (query.hotelId && query.hotelId !== "null") {
        setHotelId(query.hotelId as string);
      }

      if (query.arrivalDate && query.departureDate && query.arrivalDate !== "null" && query.departureDate !== "null") {
        setDateRange([query.arrivalDate as string, query.departureDate as string]);
      }

      if (query.filters) {
        if (Array.isArray(query.filters)) {
          setSelectedAttrs(query.filters.map(Number));
        } else {
          setSelectedAttrs(decodeURIComponent(query.filters).split(",").map(Number) || []);
        }
      }

      if (query.adults) {
        setAdults(Number(query.adults));
      }

      if (query.children) {
        setChildren(Number(query.children));
      }

      if (query.referral) {
        setReferral(query.referral as string);
      }
    }
  }, []);
};
